import { addDays, format } from 'date-fns';

export const isToday = (date: number | Date) => format(date, 'yyyy-MM-dd') === format(Date.now(), 'yyyy-MM-dd');

export const isTomorrow = (date: number | Date) =>
  format(date, 'yyyy-MM-dd') === format(addDays(Date.now(), 1), 'yyyy-MM-dd');

export const formatDateWithRelativeText = (date: number | Date) =>
  `${isToday(date) ? 'Today' : isTomorrow(date) ? 'Tomorrow' : format(date, 'P')} at ${format(date, 'HH:mm')}`;

export const parseDate = (str: string) => {
  const date = Date.parse(str);
  if (Number.isNaN(date)) {
    return undefined;
  }
  return new Date(date);
};
